import React, { useContext } from 'react';
import Header from '../interface/components/Header';
import CloseIcon from '@mui/icons-material/Close';
import { useHistory } from 'react-router-dom';

import Imagez from '../../lib/reusable/Imagez';

import { PlaceContext } from '../../core/contexts/PlaceContext';

import { URI } from '../../core/config';

const Contact = () => {
  const {
    data: {
      place: { placeId, name, mobile, address, has_image },
    },
  } = useContext(PlaceContext);
  const history = useHistory();

  const closePage = () => {
    history.push('/');
  };

  return (
    <div className="bg-app">
      <Header />
      <div className="p-fix p-top-right pc-lr mt-32 l5">
        <CloseIcon
          size={24}
          className="br-f mt-8 p-8"
          onClick={closePage}
          style={{ marginRight: -8 }}
        />
      </div>
      <div className="H100v f-c f-dirc f-aic" style={{ paddingTop: 88 }}>
        <div className="f-1 W100">
          {has_image && placeId && (
            <Imagez image={`${URI.bucket}/${placeId}/location.jpg?time=${new Date().getTime()}`} />
          )}
        </div>
        <div className="f-c f-dirc f-jcsb f-aic WH100 mt-32">
          <div className="f-c f-dirc f-aic f-1 WH100">
            <h3 className="f2b f12 ta-c t-UC">{name}</h3>
            {mobile && (
              <div className="ta-c">
                <h3 className="f2b f08 t-UC pt-16">{'Contact:'}</h3>
                <h3 className="f2 f10 t-UC pt-8">{`Mobile: ${mobile}`}</h3>
              </div>
            )}
            {address && (
              <div className="ta-c">
                <h3 className="f2b f08 t-UC pt-16">{'Address:'}</h3>
                <h3 className="f2 f10 t-UC pt-8">{address}</h3>
              </div>
            )}
          </div>

          <div className="f-c f-dirc f-jcc">
            <a className="f2 f10 ta-c" rel="noreferrer" href="https://unitpay.ro" target="_blank">
              unitpay.ro
            </a>
            <h3 className="f2 f05 ta-c t-UC pt-4">digital interactive places</h3>
          </div>
        </div>
      </div>
      <div style={{ height: 256 }} />
    </div>
  );
};

export default Contact;
