import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@mui/material';
import { URI } from '../../core/config';
import ServiceCategoriesList from './components/ServiceCategoriesList';
import EventsPlacer from '../visuals/EventsPlacer';
import CategoryCard from './components/CategoryCard';
import InactivePlace from './components/InactivePlace';
import Header from '../interface/components/Header';
import NutritionCard from './components/NutritionCard';
import Category from './components/Category';
import { LongRoundButton } from '../../lib/reusable/LongRoundButton';

import { PlaceContext } from '../../core/contexts/PlaceContext';

const Menu = ({ ageValid, setAgeValid }) => {
  const history = useHistory();
  const [activeServices, setActiveServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const {
    data: {
      place: { placeId, active, features, bookingham, name: placeName, mobile },
      cache: { services, events, top },
    },
    loading: isLoading,
  } = useContext(PlaceContext);

  const noPlaceId = !Boolean(placeId) || placeId === '';
  const spreadedLayout = Boolean(features?.theming?.spreaded);
  const hasNutrition = Boolean(features?.place?.hasButtonPDFNutrition);
  const hasBookingham = bookingham?.length > 0;

  useEffect(() => {
    const orderedServices = [];
    for (const serviceId in services.serviceId__service) {
      const service = services.serviceId__service[serviceId];
      const index = Number(service.position);
      orderedServices[index] = service;
    }
    setActiveServices(orderedServices.filter((service) => Boolean(service)));
  }, [services]);

  useEffect(() => {
    if (noPlaceId) return;
    if (!isLoading && loading) {
      setLoading(false);
    }
  }, [isLoading]);

  const categoryClick = ({ categoryId, top, cardType }) => {
    if (cardType) {
      const uri = `/${cardType}/${categoryId}`;
      history.push(uri);
      return;
    }

    if (top) {
      const uri = `/service/${categoryId}`;
      history.push(uri);
      return;
    }

    const uri = `/category/${categoryId}`;
    history.push(uri);
  };

  if (!active) {
    return <InactivePlace active={active} />;
  }

  const mainEvents = events?.main && Array.isArray(events?.main) ? Object.values(events.main) : [];
  const topEvents = mainEvents.filter((event) => event?.spread?.position === 'top');
  const hasMainEventsTop = Object.values(events?.main).length > 0 && topEvents.length > 0;
  const has1Service = activeServices.length === 1;
  const the1service = has1Service ? activeServices[0] : null;
  const has1Category = has1Service && services.serviceId__categories[the1service.id]?.length === 1;
  let the1CategoryId = null;
  if (has1Category) {
    the1CategoryId = services.serviceId__categories[the1service.id][0]?.id;
  }

  const handleGoToBookingham = () => {
    if (!hasBookingham) return;
    window.open(bookingham, '_blank');
  };

  const hasAgeValidation = features?.place?.ageValidation?.validAge !== undefined;

  if (hasAgeValidation && ageValid === undefined) {
    return (
      <div className="p-fix p-full WH100v mb0 l12 f-c f-aic f-jcc">
        <div className="f-c f-dirc f-jcc f-aic">
          <h2 className="pb-4 f1b f18 ta-c">HELLO</h2>
          <h4 className="pb-4 f1b f12 ta-c">IN ORDER TO ACCESS THIS DIGITAL MENU</h4>
          <h2 className="pb-32 f1b f12 ta-c">{`YOU MUST BE OVER ${features?.place?.ageValidation?.validAge} YEARS*.`}</h2>
          <div className="f-c" style={{ width: 240 }}>
            <LongRoundButton action={() => setAgeValid(false)} name="I am under 21" />
            <div style={{ width: 16 }} />
            <LongRoundButton action={() => setAgeValid(true)} name="I am over 21" danger />
          </div>
        </div>
      </div>
    );
  }

  if (ageValid === false) {
    return (
      <div className="p-fix p-full WH100v mb0 l12 f-c f-aic f-jcc">
        <div className="f-c f-dirc f-jcc f-aic">
          <h2 className="pb-4 f1b f18 ta-c">THANK YOU</h2>
          <h4 className="pb-4 f1b f12 ta-c">IN ORDER TO ACCESS THIS DIGITAL MENU</h4>
          <h2 className="pb-32 f1b f12 ta-c">{`YOU MUST BE OVER ${features?.place?.ageValidation?.validAge} YEARS*.`}</h2>
        </div>
      </div>
    );
  }

  return (
    <>
      {loading && <div className="p-fix p-full bg-black WH100v mb0 l12" />}
      <Header />
      <div style={{ height: 58 }} />
      <div className="m0 pb-16 l1">
        {hasMainEventsTop && (
          <div style={{ maxWidth: 600 }}>
            <EventsPlacer menuPosition="main" enforce="top" />
          </div>
        )}

        {hasMainEventsTop && (
          <div
            className="f-c f-1 f-aic mt-16 mb-16 ml-16 bg-app-separator"
            style={{ width: 'calc(100% - var(--d32))', height: 1, marginTop: 12 }}
          />
        )}

        <div className="pc-lr pb-4">
          <Grid container spacing={1}>
            {activeServices.map((service) => {
              if (!spreadedLayout && has1Service && has1Category && the1CategoryId) {
                return (
                  <div style={{ marginRight: -8 }} key={the1CategoryId}>
                    <Category categoryId={the1CategoryId} page={false} noCalls />
                  </div>
                );
              }

              if (!spreadedLayout && service.expand) {
                return (
                  <div style={{ marginRight: -8 }} key={service.id}>
                    <ServiceCategoriesList serviceId={service.id} />
                  </div>
                );
              }

              return (
                <Grid item xs={12} sm={6} md={4} lg={3} key={service.id}>
                  <CategoryCard
                    data={service}
                    action={categoryClick}
                    top={true}
                    description={service.description}
                    intdescription={service.intdescription}
                    cardType="service"
                  />
                </Grid>
              );
            })}
          </Grid>
        </div>

        {spreadedLayout ? (
          <div className="br-4 pb-16">
            <Grid container>
              {activeServices.map((service) => (
                <ServiceCategoriesList key={service.id} serviceId={service.id} />
              ))}
            </Grid>
          </div>
        ) : (
          <>
            {top && Array.isArray(top) && top.length > 0 ? (
              <>
                <div className="f-c f-aic mt-16 mb-16 ml-16 mr-16">
                  <div className="f-1 bg-app-separator" style={{ height: 1 }} />
                </div>
                <div className="pc-lr">
                  <Grid container>
                    {top.map((category) => {
                      return (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          lg={3}
                          key={category.id}
                          style={{ marginBottom: 8 }}
                        >
                          <CategoryCard
                            data={category}
                            action={categoryClick}
                            cardType="category"
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </div>
              </>
            ) : null}
          </>
        )}

        {hasNutrition && <NutritionCard />}

        <div className="mc-lr" style={{ maxWidth: 600 }}>
          <EventsPlacer menuPosition="main" enforce="bottom" />
        </div>

        <div className="mt-24" />

        <h3 className="f2b f12 ta-c t-UC" style={{ paddingBottom: mobile?.length > 0 ? 0 : 16 }}>
          {placeName}
        </h3>
        {mobile?.length > 0 && (
          <h3 className="ta-c pb-16 mt-2">
            <a href={`tel:${mobile}`} className="f2 f10 ta-c t-UC">
              {mobile}
            </a>
          </h3>
        )}

        {hasBookingham && (
          <div
            className="f-c f-dirc f-jcfe m-8"
            style={{
              height: 60,
              marginBottom: 40,
              backgroundImage: `url(${URI.bucket}/__partners__/bookingham/card.jpg?t=2)`,
              backgroundSize: '50%',
              backgroundPosition: 'top',
              backgroundRepeat: 'no-repeat',
            }}
            onClick={handleGoToBookingham}
          >
            <h3 className="f2b f09 ta-c t-UC" style={{ marginBottom: 40 }}>
              Rezervă o masă
            </h3>
            <h3 className="f2 f07 ta-c t-UC" style={{ marginBottom: -20 }}>
              Dacă plănuiești o nouă ieșire, rezervă-ți masa din timp cu Bookingham.
            </h3>
          </div>
        )}

        <div className="f-c f-dirc f-jcc">
          <a className="f2 f10 ta-c" rel="noreferrer" href="https://unitpay.ro" target="_blank">
            powered by unitpay.ro
          </a>
          <h3 className="f2 f06 ta-c t-UC pt-4">digital interactive places</h3>
        </div>

        <div className="minH100" />
        <div className="minH25" />
      </div>
    </>
  );
};

export default Menu;
