import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Popup } from '../../../lib/reusable/Popup';
import { MenuButton } from '../../../lib/reusable/MenuButton';

import { PlaceContext } from '../../../core/contexts/PlaceContext';

const MainMenuPopup = (props) => {
  const history = useHistory();
  const { isVisible, closeMenu } = props;

  const {
    data: {
      place: { placeId, pointId, features },
    },
  } = useContext(PlaceContext);

  const hasNutrition = Boolean(features?.place?.hasMenuPDFNutrition);

  const { t } = useTranslation();

  const handleClick = () => {
    setTimeout(() => isVisible && closeMenu(), 200);
  };

  useEffect(() => {
    window.addEventListener('click', handleClick, false);
    return () => {
      window.removeEventListener('click', handleClick, false);
    };
  });

  const goToNutrition = () => {
    // closeMenu();
    // const uri = `/nutrition`;
    // history.push(uri);

    const bucketUrl =
      process.env.REACT_APP_URI === 'production'
        ? 'unitpay-photobucket'
        : 'unitpay-staging-photobucket';

    const uri = `https://drive.google.com/viewerng/viewer?embedded=true&url=https://storage.googleapis.com/${bucketUrl}/${placeId}/nutrition/nutrition.pdf?time=${new Date().valueOf()}`;
    window.open(uri, '_self');
  };

  const goToInfoPage = () => {
    closeMenu();
    const uri = `/contact`;
    history.push(uri);
  };

  const goToTerms = () => {
    closeMenu();
    const uri = `/terms`;
    history.push(uri);
  };

  const goToCookies = () => {
    closeMenu();
    const uri = `/cookies`;
    history.push(uri);
  };

  const resetPlace = () => {
    closeMenu();
    window.location.replace(`${process.env.REACT_APP_DOMAIN}/${placeId}`);
  };

  if (!isVisible) return null;
  return (
    <Popup isVisible={isVisible} style={{ top: '72px', left: '14px' }}>
      {hasNutrition && (
        <div className="mb-8">
          <MenuButton action={goToNutrition} name={t('menu.nutrition')} left />
        </div>
      )}
      <div className="mb-8">
        <MenuButton action={goToInfoPage} name={t('menu.contact')} left />
      </div>
      <div className="mb-8">
        <MenuButton action={goToTerms} name={t('menu.terms')} left />
      </div>
      <div className="mb-8">
        <MenuButton action={goToCookies} name={t('menu.cookies')} left />
      </div>
      {pointId === 'default' && (
        <div className="mb-8">
          <MenuButton action={resetPlace} name="Reload" left />
        </div>
      )}
    </Popup>
  );
};

export { MainMenuPopup };
